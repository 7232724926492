<template>
    <div class="page-taskbar" @click.stop="onClick" >
      <video ref="vp" mute="mute" loop="loop" :src="videos[activeIndex]" alt="" :style="0 == activeIndex ? 'z-index: 99' : ''" />
      <div class="play-control" :class="{ play: 'isPlay' }" v-if="!isPlay">
        <img :src="isPlay ? require('../assets/images/com/icon-pause.png') : require('../assets/images/com/icon-play.png')" alt="" />
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data: () => ({
      activeIndex: 0,
      isPlay: false,
      videos: ['https://pcsource.upupoo.com/official_website/images/com/pingbao.mp4'],
    }),
    methods: {
      onClick() {
        this.isPlay = !this.isPlay
        this.isPlay ?
        this.$refs.vp.play() :  this.$refs.vp.pause() 
      },
      pause() {
        this.isPlay = false
        this.$refs.vp.pause() 
      },
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .page-taskbar {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    video {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      cursor: pointer;
    }
    .play-control {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 999;
      cursor: pointer;
      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 88px;
        height: 88px;
      }
    }
  }
  </style>
  