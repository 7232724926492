<template>
  <div class="page-taskbar"  @click.stop="onClick">
    <video ref="vp" mute="mute" loop="loop" :src="videos[activeIndex]" alt="" :style="0 == activeIndex ? 'z-index: 99' : ''" />
    
    <!-- <video mute="mute" loop="loop" v-for="(video, i) in videos" :key="i" :src="video" alt="" :style="i == activeIndex ? 'z-index: 99' : ''" @click.stop="onClick" /> -->
    <!-- <ul class="pagination">
      <li v-for="(video, i) in videos" :key="i" :class="{ active: i == activeIndex }"></li>
    </ul> -->
    <div class="play-control" :class="{ play: 'isPlay' }" v-if="!isPlay">
      <img :src="isPlay ? require('../assets/images/com/icon-pause.png') : require('../assets/images/com/icon-play.png')" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    activeIndex: 0,
    isPlay: false,
    videos: ['https://pcsource.upupoo.com/official_website/images/com/taskbar.mp4'/*, require('../assets/images/com/taskbar-2.mp4'), require('../assets/images/com/taskbar-3.mp4')*/],
  }),
  watch: {
    activeIndex() {
      this.isPlay = false
    }
  },
  methods: {
    onClick() {
      // const vs = this.$el.querySelectorAll('video')
      // vs && vs.forEach(v => v.play())
      
      this.isPlay = !this.isPlay
      this.isPlay ?
      this.$refs.vp.play() :  this.$refs.vp.pause() 
    },
    pause() {
      this.isPlay = false
      this.$refs.vp.pause() 
    },
    prev() {
      this.activeIndex = this.activeIndex > 0 ? this.activeIndex - 1 : this.videos.length - 1
    },
    next() {
      this.activeIndex = this.activeIndex < this.videos.length - 1 ? this.activeIndex + 1 : 0
    },
    mounted() {
      // const vs = this.$el.querySelectorAll('video')
      // vs && vs.forEach(v => v.play())
    },
  },
}
</script>

<style lang="scss" scoped>
.page-taskbar {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    cursor: pointer;
  }
  .pagination {
    position: absolute;
    box-sizing: border-box;
    right: 42px;
    bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 999;

    li {
      box-sizing: border-box;
      width: 16px;
      height: 16px;
      border: 2px solid black;
      border-radius: 50%;
      transition: 0.25s ease-out;
      margin-left: 12px;
      background-color: white;
      &.active {
        background-color: #f8917c;
      }
    }
  }
  .play-control {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    cursor: pointer;
    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 88px;
      height: 88px;
    }
  }
}
</style>
