<template>
  <div class="page-spectrum">
    <div class="spectrum-list">
      <div class="spectrum-list-item" v-for="(image, index) in images" :key="index" @mouseenter="image.active = true" @mouseleave="image.active = false"><img :src="image.active ? image.gif : image.src" alt="" /></div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    images: [
      {
        src: require('../assets/images/com/1.jpg'),
        gif: require('../assets/images/com/s-1.gif'),
        active: false,
      },
      {
        src: require('../assets/images/com/2.jpg'),
        gif: require('../assets/images/com/s-2.gif'),
        active: false,
      },
      {
        src: require('../assets/images/com/3.jpg'),
        gif: require('../assets/images/com/s-3.gif'),
        active: false,
      },
      {
        src: require('../assets/images/com/4.jpg'),
        gif: require('../assets/images/com/s-4.gif'),
        active: false,
      },
      {
        src: require('../assets/images/com/5.jpg'),
        gif: require('../assets/images/com/s-5.gif'),
        active: false,
      },
      {
        src: require('../assets/images/com/6.jpg'),
        gif: require('../assets/images/com/s-6.gif'),
        active: false,
      },
    ],
  }),
  methods: {},
}
</script>

<style lang="scss" scoped>
.page-spectrum {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url('../assets/images/com/pet-bg.png');

  * {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}
.spectrum-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 38px 58px;
  width: 588px;

  .spectrum-list-item {
    width: 180px;
    height: 148px;
    margin-bottom: 24px;
    background: #f8917c;
    border: 2px solid black;
    border-radius: 10px;

    img {
      width: 180px;
      height: 148px;
      border: 2px solid black;
      border-radius: 10px;
      transform: translateY(-8px);
    }
  }

  // position: absolute;
  // left: 50%;
  // top: 48px;
  // transform: translateX(-50%);
  // width: 260px;
  // height: 314px;
  // background-image: url('../assets/images/com/pet.gif');
}
.pet-message-1 {
  position: absolute;
  right: 14px;
  top: 92px;
  width: 200px;
  height: 36px;
  background-image: url('../assets/images/com/pet-1.png');
}

.pet-message-2 {
  position: absolute;
  left: 12px;
  top: 136px;
  width: 206px;
  height: 36px;
  background-image: url('../assets/images/com/pet-2.png');
}

.pet-message-3 {
  position: absolute;
  right: 26px;
  top: 262px;
  width: 184px;
  height: 36px;
  background-image: url('../assets/images/com/pet-3.png');
}

.pet-message-4 {
  position: absolute;
  left: 84px;
  top: 282px;
  width: 74px;
  height: 34px;
  background-image: url('../assets/images/com/pet-4.png');
}
</style>
